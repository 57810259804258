.Home {
  header {
    display: block;
    position: fixed;
    width: 100vw;
    //outline: 1px solid red;
    height: 60px;
    top: 0;
    background: white;
    z-index: 1000;
    box-shadow: 0 -12px 12px 12px rgba(0,0,0,0.5);
    #logo {
      display: inline-block;
      width: auto;
      height: 60px;
      line-height: 60px !important;
    }
    nav {
      display: inline-block;
      width: auto;
      float: right;
      margin: 12px;
      a {
        margin: 4px;
      }
    }
  }

  main {
    padding-top: 180px;
    //min-height: 2000px;
    //outline: 1px solid green;
    //background-image: url('./assets/bg.jpg');
    //background-size: auto 1020px;
    //background-repeat: no-repeat;
    //background-position: top center;
    background: white;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-image: url('./assets/bg.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
    }
    div {
      padding: 0 !important;
    }
    @media screen and (min-width: 1500px){
      background-size: 100vw auto;
    }
    section {
      h2 {
        margin-bottom: 20px;
      }
      h3 {
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 30px;
      }
    }
    section#section1 {
      display: block;
      position: relative;
      top: calc(50vh - 320px);
      padding: 40px;
    }
    section#section2 {
      display: block;
      position: relative;
      margin-top: calc(100vh - 440px);
      padding: 40px;
      //outline: 1px solid red;
      width: 100%;
      background: white;
      left: 0;
    }
  }

  footer {
    display: block;
    position: absolute;
    padding: 20px;
    min-height: 120px;
    box-shadow: 0 12px 12px 12px rgba(0,0,0,0.5);
    z-index: 200;
    width: 100%;
  }
}

